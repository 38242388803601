import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { useEffectOnce, useTitle } from 'react-use';

import { type DtoProduct } from '@lp-lib/api-service-client/public';

import { GamePackUtils } from '../components/Game/GamePack/utils';
import { OTPUtils } from '../components/OneTimePurchase';
import { CheckoutChoosePurchaseOption } from '../components/OneTimePurchase/OTPCheckoutChoosePurchaseOption';
import { useFeatureQueryParam } from '../hooks/useFeatureQueryParam';
import { useTimezone } from '../hooks/useTimezone';
import { apiService } from '../services/api-service';
import { makeTitle } from '../utils/common';
import { useCheckoutLoaderData } from './checkout.client';

const smallHeadCountLimit = 10;

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);
  const headcount = OTPUtils.ParseHeadcount(url.searchParams.get('headcount'));

  const result: {
    headcount: number;
    smallHeadCountPaidProduct: Nullable<DtoProduct>;
  } = {
    headcount,
    smallHeadCountPaidProduct: null,
  };

  if (headcount <= smallHeadCountLimit) {
    const [settings, products] = await Promise.all([
      apiService.product.getProductDefaults(),
      apiService.product.getPublicProducts(),
    ]);
    result.smallHeadCountPaidProduct = products.data.published.find(
      (product) => product.id === settings.data.smallHeadCountPaidProductId
    );
  }

  return result;
}

export function Component() {
  const { pack, product } = useCheckoutLoaderData();
  const { headcount, smallHeadCountPaidProduct } =
    useLoaderData<typeof clientLoader>();
  const timezone = useTimezone();
  const otpCheckout = useFeatureQueryParam('otp-checkout');
  useTitle(makeTitle(`Choose a Purchase Option | Purchase ${pack.name}`));

  const price = GamePackUtils.FindOneTimePrice(pack, headcount);

  // create OTP intent, so we can track and recover the checkout
  useEffectOnce(() => {
    if (!price || !otpCheckout) return;

    apiService.gamePack.createOTPIntent(pack.id, {
      priceId: price.id,
      path: window.location.pathname,
      queries: window.location.search,
      timezone,
    });
  });

  return (
    <CheckoutChoosePurchaseOption
      pack={pack}
      product={product}
      headcount={headcount}
      mode={headcount <= smallHeadCountLimit ? 'small-headcount' : 'default'}
      smallHeadCountPaidProduct={smallHeadCountPaidProduct}
    />
  );
}
